import moment from 'moment'

export const projectName= "Live Marvellous at Balewadi"
export const serverErrorMessage= "Something Went Wrong!"

export const custTime= "9 AM"

export const selectableTimes= ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"]

export const scheduleConfUrl = 'public_api/leads'
export const bookingUrl = 'public/bookings/new'
export const userTypeUrl = 'public/user_type'
export const enquiryFormUrl = "public/customer"

export const paymentPlans = ['CLP', 'EMI Scheme', 'EMI+CLP', 'Subvention 1:99', 'Subvention 10:90']

export function confCallParams(callParams){
  let toSend = {}
  toSend['name'] = callParams.custName
  toSend['email'] = callParams.custEmail
  toSend['phone'] = callParams.custPhone
  toSend['date'] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null
  toSend['time'] = callParams.custTime
  toSend['project'] = projectName
  return toSend
}

export function santizeBookingParams(bParams){
  let toSend = {}
  if(bParams.leadNo){
    toSend['ln'] = bParams.leadNo
  }else{
    toSend['p'] = projectName
  }
  toSend['t'] = bParams.typologyName
  toSend['f'] = bParams.floorName
  toSend['in'] = bParams.flatName
  toSend['wi'] = bParams.wingName
  toSend['pn'] = bParams.selectedPlan
  toSend['cpuu'] = bParams.cpId
  return toSend
}

export const masterJson = 
{
  "Buildings" : [
    {
      "uuid": "1",
      "key": "Plot 1",
      "value": "Plot 1",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M110.5 464.5L143.5 446.5L153.5 466L120.5 481.5L110.5 464.5Z"
    },
    {
      "uuid": "2",
      "key": "Plot 2",
      "value": "Plot 2",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M101.5 448L135 430.5L143.5 446.5L110.5 464.5L101.5 448Z"
    },
    {
      "uuid": "3",
      "key": "Plot 3",
      "value": "Plot 3",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M88.5 423L122.5 405.5L135 430.5L101.5 448L88.5 423Z"
    },
    {
      "uuid": "4",
      "key": "Plot 4",
      "value": "Plot 4",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M64 376.5L98 358.5L122.5 405.5L88.5 423L64 376.5Z"
    },
    {
      "uuid": "5",
      "key": "Plot 6",
      "value": "Plot 6",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M98 358.5L129 341.5L142 366L110.5 382.5L98 358.5Z"
    },
    {
      "uuid": "6",
      "key": "Plot 7",
      "value": "Plot 7",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M110.5 382.5L142 366L151.5 384L120 400.5L110.5 382.5Z"
    },
    {
      "uuid": "7",
      "key": "Plot 8",
      "value": "Plot 8",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M120 400.5L151.5 384L161 401L129 418.5L120 400.5Z"
    },
    {
      "uuid": "8",
      "key": "Plot 9",
      "value": "Plot 9",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M129 418.5L161 401L170.5 419L138 436L129 418.5Z"
    },
    {
      "uuid": "9",
      "key": "Plot 10",
      "value": "Plot 10",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M138 436L170.5 419L178.5 436.5L156.5 471L138 436Z"
    },
    {
      "uuid": "10",
      "key": "Plot 11",
      "value": "Plot 11",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M158 355.5L181 343.5L189.5 345.5L196.5 345L180 315L145 332.5L158 355.5Z"
    },
    {
      "uuid": "11",
      "key": "Plot 12",
      "value": "Plot 12",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M158 355.5L181 343.5L189.5 345.5L191.5 373L192.5 392L185 409L158 355.5Z"
    },
    {
      "uuid": "12",
      "key": "Plot 13",
      "value": "Plot 13",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M219.5 342.5L199.5 305L180 315L196.5 345L219.5 342.5Z"
    },
    {
      "uuid": "13",
      "key": "Plot 14",
      "value": "Plot 14",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M219.5 342.5L199.5 305L216.5 295L244 347L219.5 342.5Z"
    },
    {
      "uuid": "14",
      "key": "Plot 15",
      "value": "Plot 15",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M251.5 316.5L234 285L216.5 295L233 326L251.5 316.5Z"
    },
    {
      "uuid": "15",
      "key": "Plot 16",
      "value": "Plot 16",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M251.5 316.5L271 353L244 347L233 326L251.5 316.5Z"
    },
    {
      "uuid": "16",
      "key": "Plot 17",
      "value": "Plot 17",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M294 357.5L284.5 341.5L331.5 317L341.5 331.5L355 363L294 357.5Z"
    },
    {
      "uuid": "18",
      "key": "Plot 18",
      "value": "Plot 18",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M329.5 317.5L318 295L272.5 319L284.5 341.5L329.5 317.5Z"
    },
    {
      "uuid": "18",
      "key": "Plot 19",
      "value": "Plot 19",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M306.5 272.5L318 295L272.5 319L260.5 297L306.5 272.5Z"
    },
    {
      "uuid": "19",
      "key": "Plot 20",
      "value": "Plot 20",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M306.5 272.5L281 259.5L249 278L260.5 297L306.5 272.5Z"
    },
    {
      "uuid": "20",
      "key": "Plot 60",
      "value": "Plot 60",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M300 244.5L339.5 229L349.5 256.5L321.5 267L300 244.5Z"
    },
    {
      "uuid": "21",
      "key": "Plot 61",
      "value": "Plot 61",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M339.5 229L369.5 219L379 246L349.5 256.5L339.5 229Z"
    },
    {
      "uuid": "22",
      "key": "Plot 62",
      "value": "Plot 62",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M321.5 267L379 246L386 267L338.5 284.5L321.5 267Z"
    },
    {
      "uuid": "23",
      "key": "Plot 63",
      "value": "Plot 63",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M338.5 284.5L386 267L392 283.5L350 298.5L338.5 284.5Z"
    },
    {
      "uuid": "24",
      "key": "Plot 64",
      "value": "Plot 64",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M350 298.5L392 283.5L400.5 306L357.5 321.5L350 298.5Z"
    },
    {
      "uuid": "25",
      "key": "Plot 65",
      "value": "Plot 65",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M357.5 321.5L400.5 306L404 314.5H407.5L412.5 346L369.5 356L357.5 321.5Z"
    },
    {
      "uuid": "26",
      "key": "Plot 66",
      "value": "Plot 66",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M407.5 314.5L444 313L447.5 339L412.5 346L407.5 314.5Z"
    },
    {
      "uuid": "27",
      "key": "Plot 67",
      "value": "Plot 67",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M444 313L481.5 311L483.5 332.5L447.5 339L444 313Z"
    },
    {
      "uuid": "28",
      "key": "Plot 68",
      "value": "Plot 68",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M453 244L475.5 236.5L479 247L480.5 284.5L467 285.5L453 244Z"
    },
    {
      "uuid": "29",
      "key": "Plot 69",
      "value": "Plot 69",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M453 244L431.5 252L444 289L467 285.5L453 244Z"
    },
    {
      "uuid": "30",
      "key": "Plot 70",
      "value": "Plot 70",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M409.5 259.5L431.5 252L444 289L419.5 292L409.5 259.5Z"
    },
    {
      "uuid": "31",
      "key": "Plot 71",
      "value": "Plot 71",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M409.5 259.5L453 244L445.5 220.5L400 236L409.5 259.5Z"
    },
    {
      "uuid": "32",
      "key": "Plot 72",
      "value": "Plot 72",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M493.5 230L453 244L445.5 220.5L487 207L493.5 230Z"
    },
    {
      "uuid": "33",
      "key": "Plot 73",
      "value": "Plot 73",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M392 211L436.5 195.5L445.5 220.5L400 236L392 211Z"
    },
    {
      "uuid": "34",
      "key": "Plot 74",
      "value": "Plot 74",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M457 188L436.5 196L445.5 220.5L466 213.5L457 188Z"
    },
    {
      "uuid": "35",
      "key": "Plot 75",
      "value": "Plot 75",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M457 188L477 180.5L486.5 207L466 213.5L457 188Z"
    },
    {
      "uuid": "36",
      "key": "Plot 76",
      "value": "Plot 76",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M494 177L529 165.5L537.5 189.5L502 202L494 177Z"
    },
    {
      "uuid": "37",
      "key": "Plot 77",
      "value": "Plot 77",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M561.5 154L529 165.5L537.5 189.5L569.5 179L561.5 154Z"
    },
    {
      "uuid": "38",
      "key": "Plot 78",
      "value": "Plot 78",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M578 205L546 215.5L537.5 189.5L569.5 179L578 205Z"
    },
    {
      "uuid": "39",
      "key": "Plot 79",
      "value": "Plot 79",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M511 227L546 215.5L537.5 189.5L502 202L511 227Z"
    },
    {
      "uuid": "40",
      "key": "Plot 89",
      "value": "Plot 89",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M723.5 140.5L672 148L675.5 172.5L718.5 167.5L723.5 140.5Z"
    },
    {
      "uuid": "41",
      "key": "Plot 90",
      "value": "Plot 90",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M705 143L672 148L667 114.5L700 110.5L705 143Z"
    },
    {
      "uuid": "42",
      "key": "Plot 91",
      "value": "Plot 91",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M705 143L723.5 140.5L731 106L700 110.5L705 143Z"
    },
    {
      "uuid": "43",
      "key": "Plot 92",
      "value": "Plot 92",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M701 85.5L737 80.5L731 106L704.5 110L701 85.5Z"
    },
    {
      "uuid": "44",
      "key": "Plot 93",
      "value": "Plot 93",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M701 85.5L664 90L667 114.5L704.5 110L701 85.5Z"
    },
    {
      "uuid": "45",
      "key": "Plot 94",
      "value": "Plot 94",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M693.5 86.5L664 90L661.5 62.5L690.5 58.5L693.5 86.5Z"
    },
    {
      "uuid": "46",
      "key": "Plot 95",
      "value": "Plot 95",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "area": "1,800",
      "path": "M693.5 86.5L737 80.5L742 52.5L690.5 58.5L693.5 86.5Z"
    },
    {
      "key": "Mariegold Clubhouse",
      "value": "Mariegold Clubhouse",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/bar.png",
      "path": "M643.5 645.5L629 664L614.5 658L606.5 629.5L617 631.5L626.5 632.5L638 638.5L643.5 645.5Z"
    },
    {
      "key": "Minigolf Course",
      "value": "Minigolf Course",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/bath.png",
      "path": "M652.5 684.5L632.5 680L622.5 666.5L646.5 657L667.5 662L684 635L665.5 625L685 593.5L745.5 630.5L720.5 672.5L704 675L690 683.5L652.5 684.5Z"
    },
    {
      "key": "Gymnasium",
      "value": "Gymnasium",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/bedroom.png",
      "path": "M678.5 644.5L667.5 662L647 656.5L644 646.5L654.5 639H664.5L670 640.5L678.5 644.5Z"
    },
    {
      "key": "Multi Cuisine Restaurant",
      "value": "Multi Cuisine Restaurant",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/gaming.png",
      "path": "M676.5 587L656 618.5L658.5 624.5L651 627.5L645.5 630.5L642.5 626L637 623L630.5 634.5L640 639L644.5 646.5L655 639L658.5 638L685.5 593L676.5 587Z"
    },
    {
      "key": "Swimming Pool",
      "value": "Swimming Pool",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/gym.png",
      "path": "M623 607.5C624.2 606.7 625.5 608.5 626 609.5C619 633 641.5 629.5 643.5 625C645 624 650 614.5 642 606.5C666.5 610 659 579 642.5 584.5C637 589 631 587.5 629 586C624 577.5 605.5 576.5 603.5 591.5C600.767 612 620.5 609 623 607.5Z"
    },
    {
      "key": "Kids Club",
      "value": "Kids Club",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/hall.png",
      "path": "M602 555.5L610.5 560.5L616 552L623 556.5L607.5 580.5L592 570.5L602 555.5Z"
    },
    {
      "key": "Butterfly Flower Garden",
      "value": "Butterfly Flower Garden",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/kitchen.png",
      "path": "M516 511.5L518 499.5L536 492.5L537 512.5L540.5 522.5L539 529.5L519 538.5L506.5 534.5L491 527V518.5L514 515.5L516 511.5Z"
    },
    {
      "key": "Daisy Dome Greenhouse",
      "value": "Daisy Dome Greenhouse",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/library.png",
      "path": "M517.5 493L518 499.5L536 492.5L537 512.5L540.5 522.5L567.5 520.5L564 450.5L535.5 451.5L536 464L516 467L517 486.5L517.5 493Z"
    },
    {
      "key": "Botanical Garden",
      "value": "Botanical Garden",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/living-room.png",
      "path": "M534.5 427H564.5L564 451L535.5 452L534.5 427Z"
    },
    {
      "key": "Meditation Center",
      "value": "Meditation Center",
      "status": "Available",
      "opacity": "0.01",
      "fill": "#0267C1",
      "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/lobby.png",
      "path": "M263 178.5C263 178.5 260.5 176.5 255.5 172C244.5 184 224 168.5 221 184C218.968 194.5 232.5 196 232.5 196L247.5 201L265.5 218L274.5 208H274.5C276.5 208 286.5 208 293.5 200.5C308.5 174 273.5 162 263 178.5Z"
    },
    {
      // "key": "Lily Pond",
      // "value": "Lily Pond",
      // "status": "Available",
      // "opacity": "0.01",
      // "fill": "#0267C1",
      // "image": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/lilypond.jpg",
      // "path": "M366 193L332.5 96L181.385 148.194C194.094 184.35 236.424 178.796 256 171.5C258.333 173.5 260.5 175.5 262.5 179C275 166 288.556 173 293 179C303 196 287 209 275 208.5L269 215L282.5 227L366 193Z"
    }

  ],
  "Variants" : [
    {
      "uuid": "1",
      "key": "villa2",
      "value": "2 BHK ",
      "price": "1.30 Cr",
      "area": "890",
      "carpetArea": "890",
      "configuration": "2 BHK",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-2-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/2bhk-type1.png"
    },
    {
      "uuid": "2",
      "key": "villa3",
      "value": "3 BHK Smart",
      "price": "1.60 Cr",
      "area": "1100",
      "carpetArea": "1100 ",
      "configuration": "3 BHK Smart",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-3-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/3bhk-type1.png"
    },
    {
      "uuid": "3",
      "key": "villa4",
      "value": "3 BHK Spacious",
      "price": "1.90 Cr",
      "area": "1,400",
      "carpetArea": "1,400",
      "configuration": "3 BHK Spacious",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
      "floorPlan": " https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/3bhk-type2.png"
     
    },
    {
      "uuid": "4",
      "key": "villa5",
      "value": "4 BHK ",
      "price": "2.60 Cr",
      "area": "1,704",
      "carpetArea": "1,704",
      "configuration": "4 BHK",
      "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
      "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/4bhk-type1.png"
    },
    // {
    //   "uuid": "5",
    //   "key": "villa6",
    //   "value": "4 BHK",
    //   "price": "99L",
    //   "area": "1,800",
    //   "carpetArea": "1,500",
    //   "configuration": "5 Bed Villa",
    //   "elevation": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/nirvana/villa-4-elevation.jpg",
    //   "floorPlan": "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/platinumbuildcon/4bhk-type1.png"
    // }
  ],
}

export const FormSteps = ["Explore Project","Explore Master layout","Select Variant","Check Details","KYC & Make Payment"]